<template>
    <ul :class="layoutClass">
        <li>
            <el-checkbox v-model="item.checked" @change="$emit('singleElection')"></el-checkbox>
        </li>
        <li class="main">
            <goods-info :item="item" tag="批发"></goods-info>
            <div class="cart-info" v-html="wholesalePriceMessageNodes(item)"></div>
        </li>
        <li class="mini-li" v-if="layoutClass === 'mini'">
            <span>￥{{ priceDisplayLogic.actualPrice }} x {{ item.num }}</span>
            <el-button type="text" @click="$emit('deleteCart')">删除</el-button>
        </li>
        <li>
            <span>￥{{ priceDisplayLogic.actualPrice }}</span>
        </li>
        <li>
            <el-input-number v-model="item.num" :step="item.min_buy || 1" size="mini" :min="item.min_buy || 1"
                             :max="getMaxBuyQuantity" @change="cartNumChange"></el-input-number>
        </li>
        <li><strong class="subtotal ns-text-color">￥{{ calculationSubtotal }}</strong></li>
        <li>
            <el-button type="text" @click="$emit('deleteCart')">删除</el-button>
        </li>
    </ul>
</template>
<script>
    import CartItemMixin from "@/views/goods/cart/common/cart-item-mixin"
    
    export default {
        name: "default-cart-item",
        mixins: [CartItemMixin],
        computed: {
            calculationSubtotal() {
                let totalPrice = 0
                let { price } = this.item
                const goodsNum = this.limitPurchaseQuantity
                const actualPrice = this.priceDisplayLogic.actualPrice
                // 找到第一个满足当前购买数量的阶梯 反转数组 从高到低 找到合适的阶梯
                const tierList = this.item.promotion_info.price_json
                // 找到第一个满足当前购买数量的阶梯 反转数组 从高到低 找到合适的阶梯
                const tier = tierList.slice().reverse().find(t => Number(t.num) <= Number(goodsNum))
                if (tier) {
                    if (tier.limit_num > 0) {
                        // 超过 limit_num，使用优惠价格
                        let withinLimit = Math.min(goodsNum, tier.limit_num)
                        let overLimit = Math.max(goodsNum - tier.limit_num, 0)
                        totalPrice = actualPrice * withinLimit + overLimit * price
                    } else {
                        totalPrice = actualPrice * goodsNum
                    }
                } else {
                    totalPrice = actualPrice * goodsNum
                }
                let subtotal = Number(totalPrice).toFixed(2)
                this.item.subtotal = subtotal
                return subtotal
            },
            wholesalePriceMessageNodes() {
                return function(item) {
                    const currentQuantity = this.limitPurchaseQuantity
                    let messages = []
                    let tierList = item.promotion_info.price_json;
                    // 找到第一个满足当前购买数量的阶梯
                    const nowTier = tierList.slice().reverse().find(t => Number(t.num) <= Number(currentQuantity))
                    // 提取样式定义
                    const messageStyle = "<p style=\"color: #f56c6c; font-size: 12px;\">"
                    // 获取最低阶梯
                    let lowestTier = tierList[0]
                    let lowestLimit_num = Number(lowestTier.num)
                    
                    // 消息生成逻辑
                    if (nowTier) {
                        let limit_num = Number(nowTier.limit_num)
                        if (currentQuantity < limit_num && currentQuantity >= lowestLimit_num) {
                            messages.push(`${messageStyle}提示：当前限购活动您还可购买${limit_num - currentQuantity}${item.unit}${messageStyle}`)
                        } else if (limit_num > 0 && currentQuantity > limit_num && currentQuantity >= lowestLimit_num) {
                            messages.push(`${messageStyle}提示：您已超过限购数量${limit_num}${item.unit}恢复原价${messageStyle}`)
                            messages.push(`${messageStyle}提示：特价￥${nowTier.price} x${limit_num}，原价￥${item.price} x${currentQuantity - limit_num}${messageStyle}`)
                        }
                    }
                    if (lowestLimit_num > 0) {
                        if (currentQuantity < lowestLimit_num) {
                            messages.push(`${messageStyle}提示：当前批发活动起购数量为${lowestLimit_num}${item.unit},小于${lowestLimit_num}${item.unit}将不享受折扣${messageStyle}`)
                        }
                    }
                    
                    // 将消息数组连接成一个HTML字符串
                    return messages.join("")
                }
            }
        },
        methods: {},
        watch: {}
    }
</script>

<style lang="scss" scoped>
    @import '../common/list.scss';
</style>
