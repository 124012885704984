<template>
    <div v-if="load" :class="returnClass" :style="returnBoxStyle">
        <div v-for="(item, index) in returnShow" :key="index">
            <span :style="returnStyle" v-show="item.name">{{ item.name }}：</span>
            <span :style="returnStyle" :class="item.class ? item.class : ''">{{ item.value }}</span>
        </div>
    </div>
</template>
<script>

export default {
    props: {

        // ---------------------------  数据相关配置  ---------------------------

        info: {                        // 传递进来的信息对象
            type: Object,
            default: () => { }
        },
        type: {                        //  文本展示  默认全显示(default)   自定义(custom)
            type: String,
            default: 'default'
        },
        wesArr: {                      //  当文本展示为自定义时，需要显示西药的信息数组
            type: Array,
            default: () => []
        },
        traArr: {                      //  当文本展示为自定义时，需要显示中药的信息数组
            type: Array,
            default: () => []
        },
        expandArr: {                    //  传递可拓展显示的数据
            type: Array,
            default: () => []
        },
        notShowTitle: {               //  需要隐藏前置名称的信息数组
            type: Array,
            default: () => []
        },
        showLimit: {                  //  是否显示限制数量信息
            type: Boolean,
            default: false
        },

        // ---------------------------  样式相关配置  ---------------------------


        showClass: {                  //  可能会存在自定义的样式，在此组件新增class，再在外部传入对应名称
            type: String,
            default: 'class1'
        },
        size: {                        //  自定义字体大小
            type: String,
            default: '11px'
        },
        color: {                       //   自定义字体颜色
            type: String,
            default: '#787878'
        },
        gap: {                         //  自定义文本之间的上下间距
            type: String,
            default: '4px'
        },
        padding: {                     //  自定义容器内边距
            type: String,
            default: ''
        },
        margin: {                     //  自定义容器外边距
            type: String,
            default: ''
        }
    },
    data() {
        return {
            load: false,
            categoryArr: ['wes', 'tra'],// 西药(wes)  中药(tra)
            category: ''
        };
    },
    created() {
        this.category = this.returnCategory;
    },
    mounted() {
        this.load = true;
    },
    watch: {

    },
    computed: {
        returnCategory() {
            let id = 0;
            let name = "";
            if(this.info.goods_name || this.info.sku_name){
                name = (this.info.goods_name || this.info.sku_name);
            }
            if(this.info.goods && this.info.goods.goods_name){
                name = this.info.goods.goods_name;
            }
            let bool = false;
            if (this.info && this.info.is_medicinal >= 0) {
                id = this.info.is_medicinal;
                bool = true;
            }
            if (this.info && this.info.goods && this.info.goods.is_medicinal >= 0) {
                id = this.info.goods.is_medicinal;
                bool = true;
            }
            if(this.info && this.info.is_chinese_medicine>=0){
                id = this.info.is_chinese_medicine;
                bool = true;
            }
            if(typeof id === 'string'){
                id = Number(id);
            }
            let text = this.categoryArr[id];
            return text;
        },
        returnClass() {
            return this.showClass;
        },
        returnBoxStyle() {
            let str = 'gap:' + this.gap + ';'
            if (this.padding) {
                str += 'padding:' + this.padding + ';box-sizing: border-box;'
            }
            if (this.margin) {
                str += 'margin:' + this.margin + ';'
            }
            return str
        },
        returnStyle() {
            let str = "font-size:" + this.size + ';color:' + this.color + ';line-height:' + ( parseFloat(this.size ) + 3) + 'px;';
            return str;
        },
        returnShow() {
            if (!this.info) {
                return []
            };
            if (!this.info.attr && this.info.goods_attr) {
                this.info.attr = JSON.parse(this.info.goods_attr);
            }
            if (!this.info.attr) {
                this.info.attr = {};
            }
            // let sumArr = [
            //     { name: '厂家', class: ['wes', 'tra'], value: this.info.attr.attr_factory || '-' },
            //     { name: '规格', class: ['wes', 'tra'], value: this.info.attr.attr_specs || '-' },
            //     { name: '库存', class: ['wes'], value: this.info.stock_name || this.info.stock || this.info.goods_stock || '-' },
            //     { name: '产地', class: ['tra'], value: this.info.attr.attr_place || '-' },
            //     { name: '单位', class: ['wes', 'tra'], value: this.info.unit || '' },
            //     { name: '数量', class: ['wes', 'tra'], value: this.info.num ? this.info.num : '' },
            //     { name: '效期', class: ['wes'], value: (this.info.attr.attr_validity ? this.info.attr.attr_validity.trim() : '-') || '-' },
            //     { name: '生产日期', class: ['tra'], value: this.info.attr.attr_production_date || '-' },
            //     { name: '经营范围', class: ['wes', 'tra'], value: (this.info.business_scope_name ? this.info.business_scope_name : '无') }
            // ];
            let sumArr = [
                { name: '规格', class: ['wes', 'tra'], value: this.info.attr.attr_specs || '' },
                { name: '库存', class: ['wes', 'tra'], value: this.info.stock_name || this.info.stock || this.info.goods_stock || '' },
                { name: '产地', class: ['tra'], value: this.info.attr.attr_place || '' },
                { name: '单位', class: ['wes', 'tra'], value: this.info.unit || '' },
                { name: '效期', class: ['wes'], value: (this.info.attr.attr_validity ? this.info.attr.attr_validity.trim() : '') || '' },
                { name: '产品规格', class: ['tra'], value: this.info.attr.attr_goods_attr || '' },
                { name: '生产日期', class: ['tra'], value: this.info.attr.attr_production_date || '' },
                { name: '厂家', class: ['wes', 'tra'], value: this.info.attr.attr_factory || '' }
            ];
            let linArr = [
                { name: 'ERPID', value: this.info.goods_erp_spid || '' },
                { name: '税率', value: this.info.tax_rate || '' },
                { name: '销售量', value: (this.info && this.info.total_order_count) ? String(this.info.total_order_count) : '', class: 'redText' },
                { name: '销售额', value: (this.info && this.info.total_order_money) ? '￥' + this.info.total_order_money.toFixed(2) : '', class: 'redText' },
            ];
            if (this.type === 'default') {
                sumArr = this.fillterByClass(sumArr);
            } else {
                sumArr = this.fillterByArr(sumArr);
            }
            sumArr = this.clearName(sumArr);
            sumArr = sumArr.filter(item => item.value !== '');
            sumArr = sumArr.concat(this.fillterExpandByArr(linArr));
            if(this.showLimit){
                sumArr.unshift(
                    { name: '限购', value: parseFloat(this.info.max_buy)>0? parseFloat(this.info.max_buy)+(this.info.unit?this.info.unit:'件'):'无上限' , class: 'redText'}
                );
            }
            return sumArr;
        },
    },
    methods: {
        fillterByArr(arr) {
            let tempArr = JSON.parse(JSON.stringify(arr));
            let newArr = [];
            function pushArr(linArr) {
                tempArr.forEach(item => {
                    if (linArr.includes(item.name)) {
                        newArr.push(item);
                    }
                })
            }
            function sortArr(linArr) {
                const orderArr = linArr;
                const orderMap = new Map(orderArr.map((name, index) => [name, index]));
                newArr.sort((a, b) => {
                    const indexA = orderMap.get(a.name) !== undefined ? orderMap.get(a.name) : Infinity;
                    const indexB = orderMap.get(b.name) !== undefined ? orderMap.get(b.name) : Infinity;
                    return indexA - indexB;
                });
            }
            if (this.category === 'wes' && this.wesArr.length > 0) {
                pushArr(this.wesArr);
                sortArr(this.wesArr);
                return newArr;
            }
            if (this.category === 'tra' && this.traArr.length > 0) {
                pushArr(this.traArr);
                sortArr(this.traArr);
                return newArr;
            }
            return tempArr;
        },
        fillterExpandByArr(arr) {
            let tempArr = JSON.parse(JSON.stringify(arr));
            let newArr = [];
            if (this.expandArr.length === 0) {
                return [];
            }
            function pushArr(linArr) {
                tempArr.forEach(item => {
                    if (linArr.includes(item.name)) {
                        newArr.push(item);
                    }
                })
            }
            function sortArr(linArr) {
                const orderArr = linArr;
                const orderMap = new Map(orderArr.map((name, index) => [name, index]));
                newArr.sort((a, b) => {
                    const indexA = orderMap.get(a.name) !== undefined ? orderMap.get(a.name) : Infinity;
                    const indexB = orderMap.get(b.name) !== undefined ? orderMap.get(b.name) : Infinity;
                    return indexA - indexB;
                });
            }
            pushArr(this.expandArr);
            sortArr(this.expandArr);
            return newArr;
        },
        fillterByClass(arr) {
            let tempArr = JSON.parse(JSON.stringify(arr));
            let newArr = [];
            let classStr = this.category;
            tempArr.forEach(item => {
                if (item.class.includes(classStr)) {
                    newArr.push(item);
                }
            });
            return newArr;
        },
        clearName(arr) {
            let newArr = JSON.parse(JSON.stringify(arr));
            if (this.notShowTitle.length === 0) {
                return newArr;
            }
            newArr.forEach(item => {
                if (this.notShowTitle.includes(item.name)) {
                    item.name = '';
                }
            });
            return newArr;
        }
    }
};
</script>

<style lang="scss" scoped>
.class1 {
    display: flex;
    flex-direction: column;

    >div {
        display: flex;
        align-items: center;
        overflow: hidden;

        >span {
            font-weight: 400;
            white-space: nowrap;
        }

        >span:nth-child(2) {
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
}
.redText {
    color: #f54e4e !important;
}
</style>