<template>
    <ul :class="layoutClass">
        <li>
            <el-checkbox v-model="item.checked" @change="$emit('singleElection')"></el-checkbox>
        </li>
        <li class="main">
            <goods-info :item="item" tag="特价"></goods-info>
            <div class="cart-info" v-html="bargainPriceMessageNodes(item)"></div>
<!--            <div class="cart-info">-->
<!--                <div v-if="item.goods_min > 1 && limitPurchaseQuantity < item.goods_min" style="color: #f56c6c">-->
<!--                    <div>提示：不满足最小购买数量{{ item.goods_min }}按原价计算</div>-->
<!--                </div>-->
<!--                <div v-else-if="item.goods_limit > 0" style="color: #f56c6c">-->
<!--                    <div v-if="item.num < item.goods_limit">提示：当前为限购商品您还可够买{{ item.goods_limit - item.num-->
<!--                        }}，特价!-->
<!--                    </div>-->
<!--                    <div v-if="item.num == item.goods_limit">提示：即将超过限购数量{{ item.goods_limit-->
<!--                        }}，超出部分恢复原价(￥{{ item.price }})！-->
<!--                    </div>-->
<!--                    <div v-if="item.num > item.goods_limit">提示：已恢复原价(￥{{ item.price }}),-->
<!--                        数量为{{ item.num - item.goods_limit }}!-->
<!--                    </div>-->
<!--                    <div v-if="item.min_buy > item.goods_limit">提示：已恢复原价(￥{{ item.price }}),-->
<!--                        数量为{{ item.num - item.goods_limit }}!-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->
        </li>
        <li class="mini-li" v-if="layoutClass === 'mini'">
            <span>￥{{  priceDisplayLogic.actualPrice }} x {{item.num}}</span>
            <el-button type="text" @click="$emit('deleteCart')">删除</el-button>
        </li>
        <li>
            <span>￥{{ priceDisplayLogic.actualPrice }}</span>
        </li>
        <li>
            <el-input-number v-model="item.num" :step="item.min_buy || 1" size="mini" :min="item.min_buy || 1"
                             :max="getMaxBuyQuantity" @change="cartNumChange"></el-input-number>
        </li>
        <li><strong class="subtotal ns-text-color">￥{{ calculationSubtotal }}</strong></li>
        <li>
            <el-button type="text" @click="$emit('deleteCart')">删除</el-button>
        </li>
    </ul>
</template>
<script>
    import CartItemMixin from "@/views/goods/cart/common/cart-item-mixin"

    export default {
        name: "default-cart-item",
        mixins: [CartItemMixin],
        computed: {
            calculationSubtotal() {
                const goodsNum = this.limitPurchaseQuantity
                const actualPrice = this.priceDisplayLogic.actualPrice
                let { goods_limit, price } = this.item
                let withinLimit = Math.min(goodsNum, goods_limit)
                let overLimit = Math.max(goodsNum - goods_limit, 0)
                let totalPrice = withinLimit * actualPrice + overLimit * price
                let subtotal = Number(totalPrice).toFixed(2)
                // 先判断是否超出限购数量 goods_limit
                this.item.subtotal = subtotal
                return subtotal
            }
        },
        methods: {},
        watch: {}
    }
</script>

<style lang="scss" scoped>
    @import '../common/list.scss';
</style>
