import GoodsInfo from "@/views/goods/cart/components/goods-info.vue"

export default {
    components: { GoodsInfo },
    emits: ["singleElection", "deleteCart", "change"],
    props: {
        siteItem: {
            type: Object,
            default: () => {
            }
        },
        item: {
            type: Object,
            default: () => {
            }
        },
        layoutClass: {
            type: String,
            default: ""
        }
    },
    computed: {
        //价格展示逻辑
        priceDisplayLogic() {
            const { goods_min, promotion_label, discount_price, price, is_discount } = this.item
            const goodsNum = this.limitPurchaseQuantity
            // 是否展示折扣价格判断 小于goods_min 展示原价 否则折扣价格
            const isDiscountApplicable = goodsNum >= goods_min
            // 检查商品是否属于批发活动
            if (promotion_label === "wholesale") {
                const tierList = this.item.promotion_info.price_json
                // 找到第一个满足当前购买数量的阶梯 反转数组 从高到低 找到合适的阶梯
                const tier = tierList.slice().reverse().find(t => Number(t.num) <= Number(goodsNum))
                const priceToDisplay = tier ? tier.price : isDiscountApplicable ? discount_price : price
                return {
                    actualPrice: priceToDisplay,
                    shouldShowDiscountIcon: true
                }
            }
            // 普通商品的逻辑
            // 当前数量小于goods_min 那么取原价 否则取discount_price
            return {
                actualPrice: isDiscountApplicable ? discount_price : price,
                shouldShowDiscountIcon: isDiscountApplicable && is_discount
            }
        },
        /**
         * 限制或初始化购买数量以确保不超过最大购买数量或库存量。
         *
         * @param {Object} item - 代表商品或服务的对象。
         * @param {number} num - 当前购买数量。
         * @param {number} max_buy - 最大购买数量，若小于或等于库存，则允许购买该数量，否则最多只能购买库存量。
         * @param {number} stock - 商品或服务的库存量。
         *
         * @returns {number} 购买数量。若购买数量超过最大购买数量或库存量，则返回最大购买数量或库存量；否则返回购买数量。
         */
        limitPurchaseQuantity() {
            const { num, max_buy, stock } = this.item
            // 检查 item.max_buy 是否大于 1
            // 直接使用 item.max_buy否则使用 item.stock
            let maxBuy = max_buy > 1 ? Math.min(max_buy, stock) : stock
            // 检查 item.num 是否大于 maxBuy，如果是，则返回 maxBuy，否则返回 item.num
            return num <= maxBuy ? num : maxBuy
        },
        /**
         * 获取最大的购买数量
         * */
        getMaxBuyQuantity() {
            const { max_buy, stock } = this.item
            return max_buy > 1 ? Math.min(max_buy, stock) : stock
        },
        //特价提示富文本
        bargainPriceMessageNodes() {
            return function(item) {
                let messages = []
                const messageStyle = "<p style=\"color: #f56c6c; font-size: 12px;\">"
                const currentQuantity = this.limitPurchaseQuantity

                // 使用传入的item参数
                if (item.promotion_type === 1 && item.is_member_discount === 0) {

                    if (item.goods_limit === 0) {
                        messages.push("<p style=\"color: #31BB6D; font-size: 12px;\">提示：本特价商品无限制数量</p>")
                    } else if (currentQuantity > item.goods_min && currentQuantity < item.goods_limit) {
                        messages.push(`${messageStyle}提示：限购活动您还可购买${item.goods_limit - currentQuantity}${item.unit}${messageStyle}`)
                    } else if (currentQuantity > item.goods_limit) {
                        messages.push(`${messageStyle}提示：您已超过限购数量${item.goods_limit}${item.unit}恢复原价${messageStyle}`)
                        messages.push(`${messageStyle}提示：特价￥${item.discount_price} x${item.goods_limit}，原价￥${item.price}x${currentQuantity - item.goods_limit}${messageStyle}`)
                    }
                }
                if (item.promotion_type === 1 && item.is_member_discount > 0) {
                    messages.push(`${messageStyle}提示：现已恢复原价！${messageStyle}`)
                }
                if (item.goods_min > 0) {
                    if (currentQuantity < item.goods_min) {
                        messages.push(`${messageStyle}提示：当前商品起购数量为${item.goods_min}${item.unit},小于${item.goods_min}${item.unit}将不享受折扣${messageStyle}`)
                    }
                }
                // 将带有样式的消息数组连接成一个HTML字符串
                return messages.join("")
            }
        }
    },
    methods: {
        cartNumChange(num) {
            if (num < 1 || !num) num = 1
            this.$store
                .dispatch("cart/edit_cart_num", {
                    num,
                    cart_id: this.item.cart_id
                })
                .then(res => {
                    if (res.code >= 0) {
                        this.item.num = num
                        this.$emit("change")
                    } else {
                        this.$message({
                            message: res.message,
                            type: "warning"
                        })
                    }
                })
                .catch(err => {
                    this.$message.error(err.message)
                })
        }
    }
}
