<template>
	<div class="header-in">
		<el-row>
			<el-col :span="5">
				<router-link to="/" class="logo-wrap">
					<img v-if="siteInfo.logo" :src="$img(siteInfo.logo)" />
					<img v-else src="@/assets/images/logo.png" />
				</router-link>
			</el-col>
			<el-col :span="11">
				<div class="in-sousuo">
					<div class="sousuo-box">
						<el-dropdown @command="handleCommand" trigger="click">
							<span class="el-dropdown-link">
								{{ searchTypeText }}
								<!-- <i class="el-icon-arrow-down"></i> -->
							</span>
							<el-dropdown-menu slot="dropdown">
								<!-- <el-dropdown-item command="goods">商品</el-dropdown-item> -->
							</el-dropdown-menu>
						</el-dropdown>
						<input type="text" :placeholder="defaultSearchWords" v-model="keyword" @input="gaibian"
							@keyup.enter="search" maxlength="50" />
						<el-button type="primary" size="small" @click="search">搜索</el-button>
					</div>
					<div class="hot-search-words" v-if="hotSearchWords.length">
						<span>热门搜索：</span>
						<ul>
							<li v-for="(item, index) in hotSearchWords" :key="index"
								@click="$router.push({ path: '/list', query: { keyword: item, search_type: 'goods' } })">
								{{ item }}
							</li>
						</ul>
					</div>
					<div class="Fuzzysearch">
						<div class="Fuzzysearchcont" v-if="item != null" v-for="(item, index) in Fuzzysearchcont"
							:key="index" v-html="keyRegExp(item)" @click="vagelistclick(item)">
						</div>
					</div>
				</div>
			</el-col>

			<el-col :span="7">
				<div class="cart-wrap">
					<div class="cart" @click="jumpToCar">
						<span>我的购物车</span>
						<el-badge v-if="cartCount" :value="cartCount" type="primary"><i
								class="iconfont icon-gouwuche"></i></el-badge>
						<i v-else class="iconfont icon-gouwuche"></i>
					</div>

					<div class="list">
						<mini-cart-list />
					</div>
				</div>
			</el-col>
			<el-col :span="3">
				<div>
					<div style="display:flex;margin-top:15px; align-items: center;margin-left:20px">
						<el-image style="width: 28px; height: 28px" :src="require('../../assets/images/shop.png')"
							fit='scale-down'></el-image>
						<span class="shop" style="margin-left:5px">{{ siteInfo.site_tel }}</span>
					</div>
				</div>
			</el-col>
			<el-col :span="4">
				<el-image :src="$img(qrcode)" style="width: 100px; height: 100px">
					<div slot="error" class="image-slot">
						<img style="width: 100px; height: 100px" :src="require('@/assets/images/wx_qrcode.jpg')" />
					</div>
				</el-image>
			</el-col>
		</el-row>

	</div>
</template>

<script>
import {
	mapGetters
} from 'vuex';
import {
	apiHotSearchWords,
	apiDefaultSearchWords,
	Fuzzysearchcontdata
} from '@/api/pc';

import { copyRight, shopServiceLists, friendlyLink, weQrcode, websiteInfo } from "@/api/website"
import MiniCartList from "@/components/MiniCartList.vue"
export default {
	props: {},
	data() {
		return {
			searchType: 'goods',
			searchTypeText: '商品',
			keyword: '',
			hotSearchWords: [],
			defaultSearchWords: '',
			cartTotalPrice: 0,
			Fuzzysearchcont: [],
			qrcode: ''
		};
	},
	components: { MiniCartList },
	computed: {
		...mapGetters(['cartCount', 'siteInfo', 'defaultGoodsImage'])
	},
	created() {
		this.keyword = this.$route.query.keyword || '';
		if (this.$route.name == 'street') this.searchType = 'shop';
		else this.searchType = 'goods';
		this.$store.dispatch('site/siteInfo');
		this.getHotSearchWords();
		this.getDefaultSearchWords();
		this.getqrcodeimg()
	},
	watch: {
		searchType() {
			this.searchTypeText = this.searchType == 'goods' ? '商品' : '店铺';
		},
		$route(curr) {
			if (this.keyword !== curr.query.keyword) {
				this.keyword = curr.query.keyword;
			}
			if (this.$route.name == 'street') this.searchType = 'shop';
			else this.searchType = 'goods';
		}
	},
	methods: {
		jumpToCar(){
			const currentPath = this.$route.path;
			if(currentPath == '/cart'){
				window.location.reload();
			}else{
				this.$router.push({path: '/cart'})
			}
		},
		getqrcodeimg() {
			weQrcode({})
				.then((res) => {
					if (res.code == 0 && res.data) {
						this.qrcode = res.data
					}
				})
				.catch((err) => {
					this.$message.error(err.message)
				})
		},
		handleCommand(command) {
			this.searchType = command;
		},
		search() {
			if (this.searchType == 'goods') this.$router.push({
				path: '/list',
				query: {
					// keyword: this.keyword ? this.keyword : this.defaultSearchWords
					keyword:this.keyword
				}
			});
			else this.$router.push({
				path: '/street',
				query: {
					keyword: this.keyword
				}
			});
		},
		getHotSearchWords() {
			apiHotSearchWords({}).then(res => {
				if (res.code == 0 && res.data.words) {
					this.hotSearchWords = res.data.words.split(',');
				}
			});
		},
		getDefaultSearchWords() {
			apiDefaultSearchWords({}).then(res => {
				if (res.code == 0 && res.data.words) {
					this.defaultSearchWords = res.data.words;
				}
			});
		},
		//获取模糊搜索数据
		getFuzzysearchcont() {
			Fuzzysearchcontdata(
				{
					keyword: this.keyword
				}).then(res => {
					if (res.code == 0) {
						this.Fuzzysearchcont = res.data.list;
					} else {
						this.Fuzzysearchcont = ' ';
					}
				});
		},
		gaibian() {
			setTimeout(() => {
				if (this.keyword != null) {
					this.getFuzzysearchcont();
				} else {
					this.Fuzzysearchcont = ' ';
				}
			}, 300);
		},
		keyRegExp(str) {
			var key = this.keyword;//这里定义关键词

			key = '(' + key.replace(/([\+\.\*\|\?\-\(\[\^\$])/g, '\\$1').replace(/\s+/g, '|') + ')';//把匹配关键字中的正则符转义

			var patt = new RegExp(key, 'igm'); //传  igm  可避免关键词后面的空格造成文字不匹配问题

			var str2 = str.goods_name.replace(patt, "<span style='color:#f00;'>$1</span>");
			return str2;
		},
		vagelistclick(item) {
			this.keyword = item.goods_name
			this.search();
			// this.keyword = " "
			this.Fuzzysearchcont = '';
		}
	}
};
</script>

<style scoped lang="scss">
.header-in {
	width: $width;
	height: 89px;
	margin: 20px auto 0;

	.shop {
		font-size: 16px;
	}

	.logo-wrap {
		width: 240px;
		height: 68px;
		line-height: 68px;
		display: block;

		img {
			max-width: 180px;
			max-height: 109px;
		}
	}

	.in-sousuo {
		width: 550px;
		margin-top: 10px;
		position: relative;

		.sousuo-box {
			width: 100%;
			height: 36px;
			border: 2px solid $base-color;
			box-sizing: border-box;
			display: flex;
			align-items: center;

			.el-dropdown {
				padding: 0 10px;
				user-select: none;

				&::after {
					content: '';
					border-left: 1px solid #cfcfcf;
					margin-left: 5px;
				}
			}

			input {
				width: 380px;
				height: 22px;
				background: none;
				outline: none;
				border: none;
				margin: 4px;
				display: flex;
				flex: 1;
			}

			button {
				border-radius: 0;
				height: 100%;
				margin-left: 30px;
			}

			.el-button--small {
				padding: 10px 15px;
			}
		}

		.hot-search-words {
			width: 100%;
			height: 20px;
			margin-top: 5px;
			font-size: 12px;

			span {
				float: left;
			}

			ul {
				overflow: hidden;
				margin: 0;
				height: 21px;
				padding: 0;
				color: $ns-text-color-black;

				li {
					cursor: pointer;
					list-style: none;
					float: left;
					margin-right: 10px;

					&:hover {
						color: $base-color;
					}
				}
			}
		}

		.Fuzzysearch {
			position: absolute;
			left: 0;
			top: 37px;
			background: #fff;
			border-radius: 5px;
			width: 100%;
			z-index: 102;

			.Fuzzysearchcont {
				padding: 0 10px;
				cursor: pointer;
				border-bottom-left-radius:5px;
				border-bottom-right-radius: 5px;
			}

			.Fuzzysearchcont:hover {
				color: #FF0036;
				background: #f56c6c21;
			}
		}
	}

	.cart-wrap {
		// position: relative;
		float: right;

		.cart {
			margin-top: 10px;
			width: 95px;
			height: 36px;
			padding: 0 28px 0 19px;
			border: 1px solid #dfdfdf;
			color: $base-color;
			font-size: $ns-font-size-sm;
			display: block;
			position: absolute;
			right: 0;
			z-index: 101;

			span {
				cursor: pointer;
				line-height: 38px;
				margin-right: 10px;
			}
		}

		&:hover {
			.cart {
				border-bottom: 1px solid #ffffff;
			}

			.list {
				display: block;
			}
		}

		.list {
			position: absolute;
			top: 47px;
			right: 0;
			width: 400px;
			background: #fff;
			border: 1px solid #dfdfdf;
			display: none;
			z-index: 100;

			h4 {
				height: 25px;
				padding: 6px 8px;
				line-height: 25px;
			}

			.overflow-wrap {
				width: 340px;
				overflow: hidden;

				ul {
					max-height: 335px;

					&.overflow {
						overflow: auto;
						width: 354px;
					}

					li {
						background-color: #fff;
						display: block;
						font-size: 12px;
						padding: 8px 10px;
						position: relative;
						border-bottom: 1px solid #dfdfdf;
						overflow: hidden;

						.img-wrap {
							width: 60px;
							height: 60px;
							margin-right: 5px;
							overflow: hidden;
							float: left;
							text-align: center;
							line-height: 50px;
						}

						.goods-name {
							float: left;
							min-width: 140px;
							white-space: nowrap;
							display: -webkit-box;
							-webkit-box-orient: vertical;
							-webkit-line-clamp: 2;
							overflow: hidden;
							margin-right: 10px;
						}

						.operation {
							float: right;
							text-align: right;
							width: 90px;
							margin-right: 10px;

							p {
								color: $base-color;
								// overflow: hidden;
								font-size: 16px;
								// text-overflow: ellipsis;
							}

							span {
								cursor: pointer;
								border-radius: 5px;
								padding: 2px 5px;
								border: 1px solid red;
								background: #ff003797;
								//    color: $base-color;
								color: #fff;

								&:hover {
									color: #fff;
									background: #ff0037d4;
								}
							}
						}

						&:last-child {
							border-bottom: 0;
						}
					}
				}
			}

			.total {
				background-color: #fff;
				display: block;
				font-size: 12px;
				padding: 8px 10px;
				position: relative;
				border-bottom: 1px solid #dfdfdf;
				overflow: hidden;
				background-color: #f0f0f0;
				border-bottom: 0;
				left: 0;

				span {
					margin-top: 5px;
					display: inline-block;
					width: 70%;
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;

					strong {
						margin: 0 2px;
						color: $base-color;
						font-size: $ns-font-size-base;
					}
				}

				button {
					float: right;
				}
			}

			.empty {
				width: auto;
				height: 70px;
				line-height: 70px;
				text-align: center;
				color: #999;

				i {
					font-size: 28px;
				}

				span {
					display: inline-block;
					font-size: 12px;
					padding-right: 20px;
					margin-left: 10px;
				}
			}
		}
	}
}
</style>
