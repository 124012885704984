export default {
    // api请求地址
    // baseUrl: "https://b2b.lzbctyyw.com/",
    baseUrl: "https://xyf.shenglitaiyy.com/",  //https://juxie.shenglitaiyy.com/
    // baseUrl: "http://localhost:80",
    // 图片域名
    imgDomain: "https://xyf.shenglitaiyy.com/", //https://yddv4.shenglitaiyy.com/     /https://juxie.shenglitaiyy.com/
    // 前端域名
    webDomain: "",
    // 腾讯地图key
    mpKey: "",
    // 客服
    webSocket: "",
    //本地端主动给服务器ping的时间, 0 则不开启 , 单位秒
    pingInterval: 1500
}
