<template>
    <ul :class="layoutClass">
        <li>
            <el-checkbox v-model="item.checked" @change="$emit('singleElection')"></el-checkbox>
        </li>
        <li class="main">
            <goods-info :item="item" tag="秒杀"></goods-info>
            <div class="cart-info" v-html="bargainPriceMessageNodes(item)"></div>
        </li>
        <li class="mini-li" v-if="layoutClass === 'mini'">
            <span>￥{{  item.discount_price }} x {{item.num}}</span>
            <el-button type="text" @click="$emit('deleteCart')">删除</el-button>
        </li>
        <li>
            <span>￥{{ item.discount_price }}</span>
        </li>
        <li>
            <el-input-number v-model="item.num" :step="item.min_buy || 1" size="mini" :min="item.min_buy || 1" :max="item.stock" @change="cartNumChange"></el-input-number>
        </li>
        <li><strong class="subtotal ns-text-color">￥{{ calculationOfPrice(item) }}</strong></li>
        <li>
            <el-button type="text" @click="$emit('deleteCart')">删除</el-button>
        </li>
    </ul>
</template>
<script>
    import CartItemMixin from "@/views/goods/cart/common/cart-item-mixin"
    export default {
        name: 'default-cart-item',
        mixins: [CartItemMixin],
        computed: {
            calculationOfPrice() {
                return (item) => {
                    let {num,goods_limit,price,discount_price} = item;
                    let withinLimit = Math.min(num, goods_limit);
                    let overLimit = Math.max(num - goods_limit, 0);
                    let totalPrice = withinLimit * discount_price + overLimit * price;
                    this.item.subtotal = totalPrice.toFixed(2)
                    // 先判断是否超出限购数量 goods_limit
                    return this.item.subtotal
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
@import '../common/list.scss';
</style>
