<template>
    <ul :class="layoutClass">
        <li>
            <el-checkbox v-model="item.checked" @change="$emit('singleElection')"></el-checkbox>
        </li>
        <li class="main">
            <goods-info :item="item"></goods-info>
            <div class="cart-info"></div>
        </li>
        <li class="mini-li" v-if="layoutClass === 'mini'">
            <span>￥{{ item.discount_price }} x {{item.num}}</span>
            <el-button type="text" @click="$emit('deleteCart')">删除</el-button>
        </li>
        <li>
            <span>￥{{ item.discount_price }}</span>
        </li>
        <li>
            <el-input-number v-model="item.num" :step="item.min_buy || 1" size="mini" :min="item.min_buy || 1"
                             :max="item.stock" @change="cartNumChange"></el-input-number>
        </li>
        <li><strong class="subtotal ns-text-color">￥{{ calculationOfPrice }}</strong></li>
        <li>
            <el-button type="text" @click="$emit('deleteCart')">删除</el-button>
        </li>
    </ul>
</template>
<script>
    import CartItemMixin from "@/views/goods/cart/common/cart-item-mixin"

    export default {
        name: "default-cart-item",
        mixins: [CartItemMixin],
        computed: {
            calculationOfPrice() {
                let subtotal = Number(this.item.discount_price * this.item.num).toFixed(2)
                // 先判断是否超出限购数量 goods_limit
                this.item.subtotal = subtotal
                return subtotal
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import '../common/list.scss';
</style>
