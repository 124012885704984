import store from "@/store/index.js"

const NO_LOGIN_TEXT = "登录可见"
const DEFAULT_NOT_BUY_MSG = "暂无权限-"
const DEFAULT_BUY_BTN_TEXT = "立即购买"

export function hasPermission(goods) {
    return store.state.member.token && goods.sale_control && goods.sale_control.is_member_by
}

export function getGoodsPromotion(goods) {
    return [
        {
            condition: () => !!goods.get_free_goods,
            type: "getfree",
            content: "换购"
        },
        {
            condition: () => goods.promotion_label === "seckill",
            type: "seckill",
            content: "秒杀"
        },
        {
            condition: () => goods.promotion_label === "discount",
            type: "discount",
            content: "折扣"
        },
        {
            condition: () => goods.promotion_label === "wholesale" || goods.wholesale_goods_id,
            type: "wholesale",
            content: "批发"
        },
        {
            condition: () => !!goods.bl_id,
            type: "bundling",
            content: "组合套餐"
        }
    ].find(({ condition }) => condition())
}

export function priceText(goods, value) {
    if (!store.state.member.token) {
        return NO_LOGIN_TEXT
    }
    //控销判断
    if (goods.sale_control && !goods.sale_control?.is_member_by) {
        return goods.sale_control?.not_buy_msg || DEFAULT_NOT_BUY_MSG
    }
    let price = value;
    if (!value) {
        price = goods.price
        if (goods.member_price && parseFloat(goods.member_price) < parseFloat(price)) price = goods.member_price
    }
    return price
}

export function buyBtnAuth(item, text) {
    if (!store.state.member.token) {
        return {
            text: NO_LOGIN_TEXT,
            disabled: true
        }
    }
    if (item.sale_control && item.sale_control.view_price) {
        return {
            text: text || DEFAULT_BUY_BTN_TEXT,
            disabled: false
        }
    } else {
        return {
            text: item.sale_control.not_buy_msg || DEFAULT_NOT_BUY_MSG,
            disabled: true
        }
    }
}
