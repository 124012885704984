<script>
    import ctrlShowText from "@/components/ctrl-show-text/ctrl-show-text.vue";
    export default {
        name: "goods-info",
        components: {ctrlShowText},
        props: {
            item: {
                type: Object,
                default: () => {
                }
            },
            tag: {
                type: String,
                default: ""
            },
            activity:{
                type: String,
                default: ""
            }
        },
        computed:{
            returnArr(){
                let obj = {
                    wesArr:['规格','效期','厂家'],
                    traArr:['规格','生产日期','厂家']
                }
                if(this.activity === "组合套餐"){
                    obj.wesArr = ['规格','数量','效期','厂家']
                    obj.traArr = ['规格','数量','生产日期','厂家']
                }
                return obj;
            }
        }
    }
</script>

<template>
    <div class="goods-info-wrap" @click="$router.pushToTab({ path: '/sku-' + (item.sku_id || item.goods_id) })">
        <div class="img-wrap"><img class="img-thumbnail" :src="$img(item.sku_image)" /></div>
        <div class="info-wrap">
            <p  class="title">
                <el-tag size="mini" v-if="tag" type="error" style="margin-right: 6px">{{ tag }}</el-tag>
                <span>{{ item.sku_name }}</span>
            </p>
            <ctrlShowText :showLimit="tag==='秒杀'" :info="item" type="custom" size="12px" gap="10px" margin="10px 0"
            :wesArr="returnArr.wesArr" :traArr="returnArr.traArr"></ctrlShowText>
        </div>
    </div>
</template>

<style scoped lang="scss">
    .goods-info-wrap {
        display: flex;
        .img-wrap {
            width: 60px;
            height: 60px;
            margin-right: 10px;
            cursor: pointer;
        }

        .info-wrap {
            cursor: pointer;
            .title {
                font-size: 14px;
                line-height: 1;
                padding-bottom: 6px;
            }
            .familiar {
                color: #878585;
                font-size: 12px;
                span {
                    font-size: $ns-font-size-sm;
                    color: #9a9a9a;
                }
            }


        }
    }
</style>
