<template>
    <div id="app">
        <transition name="slide"><router-view  :key="currentKey"/></transition>
    </div>
</template>
<script>
    export default {
        name: "APP",
        computed: {
    currentKey() {
      // 解决方案选项卡刷新失效，避免不必要的刷新
      if (this.$route.name === 'list_other') {
        return 'static-key';
      }
      return this.$route.fullPath;
    }
  }
    }
</script>
<style lang="scss" scoped>


    /*每个页面公共css */
    @import url("assets/styles/iconfont.css");
    @import "assets/styles/main.scss";
     @import url("assets/styles/icontfontSecond/iconfont.css");
</style>
<style>
html,body{

        /* 自定义滚动条样式 */
        ::-webkit-scrollbar {
            width: 6px; /* 纵向滚动条宽度 */
            height: 6px; /* 横向滚动条高度 */
        }

        /* 滚动条轨道 */
        ::-webkit-scrollbar-track {
            background: #f1f1f1; 
        }

        /* 滚动条滑块 */
        ::-webkit-scrollbar-thumb {
            background: #888; 
            border-radius: 6px;
        }

        /* 滚动条滑块悬停时 */
        ::-webkit-scrollbar-thumb:hover {
            background: #555; 
        }
}
.hoverBox:hover{
    .goodsName{
        font-size: 15px !important;
        font-weight: bold !important;
        color: #ff547b !important;
    }
}
.hoverBoder:hover{
    border: 1px solid #ff547b !important;
    box-sizing: border-box;
}
</style>
