<template>
    <div>
       <div style="padding: 10px">
           <template v-for="(item, cartIndex) in cartList">
               <div class="item">
                   <component :is="cartItemComponent(item)" :item="item" :key="cartIndex" layout-class="mini" @deleteCart="deleteCart(cartIndex)"></component>
               </div>
           </template>
           <div class="empty" v-if="!cartList.length">
               <i class="iconfont icon-gouwuche"></i>
               <span>您的购物车是空的，赶快去逛逛，挑选商品吧！</span>
           </div>
       </div>
        <div class="total">
            <span>共{{ cartList.length }}件商品</span>
            <el-button type="primary" size="mini" @click="toCart">去购物车</el-button>
        </div>
    </div>
</template>
<script>
    import { cartList } from "@/api/goods/cart"
    import SeckillCartItem from "@/views/goods/cart/components/seckill-cart-item.vue"
    import DiscountCartItem from "@/views/goods/cart/components/discount-cart-item.vue"
    import wholesaleCartItem from "@/views/goods/cart/components/wholesale-cart-item.vue"
    import GetfreeCartItem from "@/views/goods/cart/components/getfree-cart-item.vue"
    import BundlingCartItem from "@/views/goods/cart/components/bundling-cart-item.vue"
    import DefaultCartItem from "@/views/goods/cart/components/default-cart-item.vue"
    import { getGoodsPromotion } from  "@/utils/permission"
    import { mapGetters } from "vuex"

    export default {
        name: "MiniCartList",
        data() {
            return {
                cartList: [],
                invalidGoods: [],
                loading: true
            }
        },
        created() {
            this.getCartList()
        },
        watch: {
            cartCount() {
                this.getCartList();
            },
            member() {
                if (!this.member) {
                    this.$store.commit('cart/SET_CART_COUNT', 0);
                    this.cartList = [];
                }
            }
        },
        computed: {
            ...mapGetters(['cartCount', 'member'])
        },
        methods: {
            toCart() {
                this.$router.push('/cart')
            },
            getCartList() {
                cartList({})
                    .then(res => {
                        if (res.code >= 0) {
                            this.handleCartList(res.data, res.bundling)
                        }
                        this.loading = false
                    })
                    .catch(res => {
                        this.loading = false
                    })
            },
            handleCartList(data = [], bundling = []) {
                this.cartList = [...data, ...bundling];
            },
            cartItemComponent(item) {
                const component = {
                    seckill: SeckillCartItem,
                    discount: DiscountCartItem,
                    wholesale: wholesaleCartItem,
                    getfree: GetfreeCartItem,
                    bundling: BundlingCartItem
                }
                const promotion = getGoodsPromotion(item);
                return promotion ? component[promotion.type] : DefaultCartItem
            },
            // 删除单个购物车商品
            deleteCart(index) {
                this.$store
                    .dispatch('cart/delete_cart', {
                        cart_id: this.cartList[index].cart_id.toString()
                    })
                    .then(res => {
                        if (res.code >= 0) {
                            this.cartList.splice(index, 1);
                        }
                    })
                    .catch(err => { });
            }
        }
    }
</script>

<style scoped lang="scss">
.total {
    padding: 10px 15px;
    background: #f2f4f6;
    display: flex;
    justify-content: space-between;
    align-items: center;
    button {
        background: linear-gradient( 44deg, #F17F3A 0%, rgba(240,120,82,0.07) 28%, rgba(240,115,100,0) 49%, rgba(247,92,111,0.47) 73%, #FF427C 100%), #FF638B;
        border: none;
    }
}
.item {

    margin-bottom: 10px;
    border-bottom: 1px solid #f2f4f6;
}
</style>
