<template>
    <div>
        <div class="header-top" id="header-top">
            <div class="top-content">
                <div>
                 <router-link v-if="!logined" to="/login" class="ns-text-color" style="margin-right: 10px;">您好，请登录</router-link>
                    <div v-if="logined" class="member-info">
                        <router-link to="/member/index">{{ member.nickname || member.username }}</router-link>
                        <span @click="logout" style="margin-left: 10px;" class="ot">退出</span>
                    </div>
                    <router-link v-if="!logined" to="/register">免费注册</router-link>
                </div>
                <div class="top-right">
             
                    <router-link to="/index">首页</router-link>
                    <router-link to="/member/order_list">我的订单</router-link>
                    <router-link to="/member/footprint">我的足迹</router-link>
                    <router-link to="/member/collection">我的关注</router-link>

                    <router-link to="/cms/help">帮助中心</router-link>
                    <router-link to="/cms/notice">公告</router-link>
                    <!-- <el-dropdown v-if="qrcode">
                        <span class="el-dropdown-link">
                            小程序二维码
                            <i class="el-icon-arrow-down el-icon--right"></i>
                        </span>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item>
                                <div class="mobile-qrcode">
                                    <el-image :src="$img(qrcode)"  style="width: 100px; height: 100px" >
                                        <div slot="error" class="image-slot">
                                            <img style="width: 100px; height: 100px" :src="require('@/assets/images/wx_qrcode.jpg')" />
                                        </div>
                                    </el-image>
                                </div>
                            </el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown> -->
                    <div>
                        <el-button type="primary" plain style="padding: 5px 8px" size="small" @click="shoucang(siteInfo.site_name, '.url', 'https://b2b.scflk.com/')">保存到桌面</el-button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex"
import { getToken } from "@/utils/auth"
import { copyRight, shopServiceLists, friendlyLink, weQrcode, websiteInfo } from "@/api/website"

export default {
    props: {},
    data() {
        return {
            qrcode:''
        }
    },
    created() {
        this.$store.dispatch("site/qrCodes")
        this.$store.dispatch("member/member_detail")
        this.$store.dispatch("site/defaultFiles")
        this.$store.dispatch("site/addons")
        this.getqrcodeimg()
    },
    mounted() {},
    watch: {},
    methods: {
        shoucang(a, b, c) {
            let httpName = this.siteInfo.site_name;
            let httpUrl = window.location.href;
            this.downloadHtml(httpName,httpUrl);
        },
        downloadHtml(httpName,httpUrl) {
            // 定义要下载的 HTML 内容  
            let content = ``;
            content+=`<!DOCTYPE html>`;
            content+=`<html lang="zh">`;
            content+=`<head>`;
            content+=`<meta charset="UTF-8">`;
            content+=`<meta name="viewport" content="width=device-width, initial-scale=1.0">`;
            content+=`<title>${httpName}</title>`;
            content+=`<meta http-equiv="refresh" content="0; url=${httpUrl}" />`;
            content+=`</head>`;
            content+=`<body>`;
            content+=`</body>`;
            content+=`</html>`;
            const blob = new Blob([content], { type: 'text/html' });
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.download = (httpName+'.html');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(url);
        },
        saveToDesktop(fileName, data) {
            let explorer = navigator.userAgent.toLowerCase()
            if (explorer.indexOf("trident") > 0) {
                //IE 5+
                let wss = new ActiveXObject("WScript.Shell")
                let desktopUrl = wss.SpecialFolders("Desktop")
                let fso = new ActiveXObject("Scripting.FileSystemObject")
                let tf = fso.CreateTextFile(desktopUrl + "\\" + fileName, true)
                tf.Write(data)
                tf.Close()
            } else {
                //fireFox chrome
                let urlObj = window.URL || window.webkitURL || window
                let blob = new Blob([data])
                let link = document.createElementNS("http://www.w3.org/1999/xhtml", "a")
                link.href = urlObj.createObjectURL(blob)
                link.download = fileName
                saveClick(link)
            }

            function saveClick(obj) {
                let e = document.createEvent("MouseEvents")
                e.initMouseEvent("click", true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null)
                obj.dispatchEvent(e)
            }
        },
        logout() {
            this.$store.dispatch("member/logout")
        },
        getqrcodeimg() {
            weQrcode({})
                .then((res) => {
                    if (res.code == 0 && res.data) {
                        this.qrcode = res.data
                    }
                })
                .catch((err) => {
                    this.$message.error(err.message)
                })
        },
        adRightImageError(index) {
            this.adRightList[index].adv_image = this.defaultGoodsImage
        }
    },
    components: {},
    computed: {
        ...mapGetters(["wapQrcode", "member", "addonIsExit",'siteInfo']),
            // qrcode: function () {
            //     // return this.wapQrcode === "" ? "" : this.wapQrcode.path.h5.img
            //       return this.wapQrcode === "" ? "" : this.wapQrcode.path.h5.img
            // },
        logined: function () {
            return this.member !== undefined && this.member !== "" && this.member !== {}
        }
    }
}
</script>

<style scoped lang="scss">
.header-top {
    width: 100%;
    height: 31px;
    font-size: 12px;
    background-color: #f5f5f5;

    .el-dropdown {
        font-size: $ns-font-size-sm;
    }

    .top-content {
        width: $width;
        height: 100%;
        margin: 0 auto;
        justify-content: space-between;
        align-items: center;
      display: flex;
        .top-right {
            height: 100%;
            font-size: $ns-font-size-sm;
            color: $ns-text-color-black;
            a {
                line-height: 31px;
                padding: 0 10px;
                &:hover {
                    color: $base-color;
                }
            }

            div {
                float: left;
                height: 100%;
               
                cursor: pointer;
                line-height: 31px;
                padding: 0 5px;

                & .member-info {
                    span:first-child {
                        margin-right: 10px;
                    }
                  
                }

                span:hover {
                    color: $base-color;
                }

                &.el-dropdown:hover {
                    background-color: #fff;
                }
            }
        }
    }
}

.mobile-qrcode {
    padding: 10px 0;
}

.router-link-active {
    color: $base-color;
}
    .ot:hover{
         color: $base-color;
  }
// .router-link-exact-active{
//  color: $base-color;
// }
</style>
