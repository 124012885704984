<template>
    <ul :class="layoutClass">
        <li>
            <el-checkbox v-model="item.checked" @change="$emit('singleElection')"></el-checkbox>
        </li>
        <li class="main">
            <div style="display: flex;flex-direction: column;">
                <goods-info :item="item" tag="换购"></goods-info>
                <div class="cutom-cart-info">
                    <template v-if="layoutClass !== 'mini'">
                        <el-popover placement="top" width="800" trigger="click">
                        <div class="getfree-wrapper">
                            <div class="row header">
                                <div class="goods">商品信息</div>
                                <div class="item">换购价格</div>
                                <div class="item">数量</div>
                                <div class="item">小计</div>
                            </div>
                            <div class="row" v-for="(free_item, index) in item.get_free_goods.getFreeGoods">
                                <div class="goods">
                                    <goods-info :item="freeGoodsInfoAdpter(free_item)"></goods-info>
                                </div>
                                <div class="item">￥{{ showFreePrice(free_item) }}</div>
                                <div class="item">{{ computeFreeGoodsNum(free_item) }}</div>
                                <div class="item" style="color: #ec4944">
                                    ￥{{ (parseFloat(computeFreeGoodsNum(free_item)) * parseFloat(showFreePrice(free_item))).toFixed(2) }}
                                </div>
                            </div>
                            <div v-if="!item.get_free_goods.getFreeGoods.length" class="empty"
                                 style="text-align: center;padding: 15px 0">暂无换购商品
                            </div>
                        </div>
                        <button class="cutom-cart-info-box" slot="reference">
                            <div>
                                <template v-for="item1 in returnCartInfo">
                                    <p>{{ item1 }}</p>
                                </template>
                            </div>
                        </button>
                        
                    </el-popover>
                    </template>
                    
                </div>
            </div>
        </li>
        <li class="mini-li" v-if="layoutClass === 'mini'">
            <span>￥{{  item.discount_price }} x {{item.num}}</span>
            <el-button type="text" @click="$emit('deleteCart')">删除</el-button>
        </li>
        <li>
            <span>￥{{ item.discount_price }}</span>
        </li>
        <li>
            <el-input-number v-model="item.num" :step="item.min_buy || 1" size="mini" :min="item.min_buy || 1"
                             :max="item.stock" @change="cartNumChange"></el-input-number>
        </li>
        <li><strong class="subtotal ns-text-color">￥{{ calculationOfPrice(item) }}</strong></li>
        <li>
            <el-button type="text" @click="$emit('deleteCart')">删除</el-button>
        </li>

    </ul>
</template>
<script>
    import { info } from "@/api/member/info";
import CartItemMixin from "@/views/goods/cart/common/cart-item-mixin"

    export default {
        name: "default-cart-item",
        mixins: [CartItemMixin],
        methods: {
            freeGoodsInfoAdpter(item) {
                return {
                    ...item,
                    sku_name: item.goods.goods_name,
                    sku_image: item.goods.goods_image,
                    sku_id: item.goods.sku_id
                }
            },

            computeFreeGoodsNum(free_item) {
                if(!free_item){
                    return "";
                }
                let num = parseInt(free_item.num);
                let main_num = this.item.num;
                if(this.item.get_free_goods.is_ladder > 0){
                    if(typeof free_item.ladder_json === 'string'){
                        free_item.ladder_json = JSON.parse(free_item.ladder_json)
                    }
                    free_item.ladder_json.forEach((item1,index)=>{
                        if(main_num>=parseFloat(item1.mainRang)){
                            num = parseInt(item1.rang);
                        }
                    })
                }
                return num;
            },
            showFreePrice(info){
                if(!info){
                    return "";
                }
                let price = info.price;
                if (this.item.get_free_goods.is_ladder > 0) {
                    price = info.goods.price;
                    if(typeof info.ladder_json === 'string'){
                        info.ladder_json = JSON.parse(info.ladder_json)
                    }
                    let num = this.item.num;
                    info.ladder_json.forEach((item1,index)=>{
                        if(num>=parseFloat(item1.mainRang)){
                            price = parseFloat(item1.rangPrice);
                        }
                    })
                }
                return price
            },
        },
        computed: {
            calculationOfPrice() {
                return (item) => {
                    let { num, goods_limit, price, discount_price } = item
                    let withinLimit = Math.min(num, goods_limit)
                    let overLimit = Math.max(num - goods_limit, 0)
                    let totalPrice = withinLimit * discount_price + overLimit * price
                    this.item.subtotal = totalPrice.toFixed(2)
                    // 先判断是否超出限购数量 goods_limit
                    return this.item.subtotal
                }
            },
            returnCartInfo(){
                let arr = [];
                if(!this.item){
                    return arr;
                }
                let newArr = JSON.parse(JSON.stringify(this.item.get_free_goods.getFreeGoods));
                let mainObj = this.item.get_free_goods;
                let mainId = this.item.goods_id;
                let is_ladder = (mainObj.is_ladder>0?true:false);
                let default_unit = '盒';
                let findIdex = newArr.findIndex((item) => {
                    return item.goods_id === mainId;
                });
                // 副商品中的本品排在首位
                if(findIdex !== -1){
                    let deletObj=newArr.splice(findIdex, 1)[0];
                    let str = ''
                    if(is_ladder){
                        if(typeof deletObj.ladder_json === 'string'){
                            deletObj.ladder_json = JSON.parse(deletObj.ladder_json)
                        }
                        deletObj.ladder_json.forEach((item1,index)=>{
                            if(this.item.num>=parseFloat(item1.mainRang)){
                                let str1=(item1.mainRang + (item1.unit?item1.unit:default_unit));
                                let str2=(parseFloat(item1.rangPrice)*parseFloat(item1.rang)).toFixed(2);
                                let str3=(item1.rang+(item1.unit?item1.unit:default_unit));
                                str = `单次购买本商品${str1}+${str2}元换购${str3}本品`;
                            }
                        })
                    }else{
                        let str1=(deletObj.count + (deletObj.unit?deletObj.unit:default_unit));
                        let str2=(parseFloat(deletObj.price)*parseFloat(deletObj.num)).toFixed(2);
                        let str3=(deletObj.num+(deletObj.unit?deletObj.unit:default_unit));
                        str = `单次购买本商品${str1}+${str2}元换购${str3}本品`
                    }
                    arr.push(str);
                }
                // 处理剩下副商品
                if(newArr.length>0){
                    if(is_ladder){
                        let ladder_arr = [];
                        newArr.forEach((item1,index)=>{
                            if(typeof item1.ladder_json === 'string'){
                                item1.ladder_json = JSON.parse(item1.ladder_json)
                            }
                            let ladder_str = '';
                            let goodName = item1.goods.goods_name;
                            item1.ladder_json.forEach((item2,index)=>{
                                if(this.item.num>=parseFloat(item2.mainRang)){
                                    let str1=(item2.mainRang + (item2.unit?item2.unit:default_unit));
                                    let str2=(parseFloat(item2.rangPrice)*parseFloat(item2.rang)).toFixed(2);
                                    let str3=(item2.rang+(item2.unit?item2.unit:default_unit));
                                    ladder_str = `单次购买本商品${str1}+${str2}元换购${str3}${goodName}`;
                                }
                                
                            })
                            ladder_arr.push(ladder_str);
                        })
                        arr = arr.concat(ladder_arr);
                    }else{
                        let arr1 = [];
                        newArr.forEach((item1,index)=>{
                            let str1=(item1.count + (item1.unit?item1.unit:default_unit));
                            let str2=(parseFloat(item1.price)*parseFloat(item1.num)).toFixed(2);
                            let str3=(item1.num+(item1.unit?item1.unit:default_unit));
                            arr1.push(`单次购买本商品${str1}+${str2}元换购${str3}${item1.goods.goods_name}`);
                        })
                        arr = arr.concat(arr1);
                    }
                }
                return arr;
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import '../common/list.scss';

    .getfree-wrapper {
        .header {
            background: #f1f4f6;
            margin-bottom: 10px;

            .goods {
                text-align: center;
            }
        }

        .row {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 8px 0;
            border-bottom: 1px solid #f1f4f6;

            .goods {
                flex: 1;
            }

            .item {
                width: 100px;
                text-align: center;
            }

            &:last-of-type {
                border-bottom: none;
            }
        }
    }
    .cutom-cart-info{
        display: flex;
        flex-direction: column;
        padding-left: 70px;
        box-sizing: border-box;
    }
    .cutom-cart-info-box{
        background-color: transparent;
        outline: none;
        border: none;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        padding: 0;
        margin: 0;
        >div{
            display: flex;
            flex-direction: column;
            gap: 6px;
            >p{
                text-align: left;
                color: #ff547b;
                font-size: 12px;
                line-height: 14px;
                cursor: pointer;
            }
        }
    }
</style>
