<template>
    <div :class="layoutClass">
        <div class="bundling-header">
            <div class="check" style="width: 3%;margin-left: 30px;">
                <el-checkbox v-model="item.checked" @change="$emit('singleElection')"></el-checkbox>
            </div>
            <p>
                <el-tag size="mini" type="error" style="margin-right: 6px">组合套餐</el-tag>
                <span class="title" style="font-size: 14px">{{ item.bl_name }}</span>
            </p>
        </div>
        <ul>
            <li></li>
            <li class="main">
                <template v-for="bundlingGoodd in item.bundlingGoods">
                    <goods-info activity="组合套餐" :item="bundlingGoodd" style="margin-top: 15px"></goods-info>
                </template>
                <div class="cart-info"></div>
            </li>
            <li class="mini-li" v-if="layoutClass === 'mini'">
                <span>￥{{ item.bl_price }} x {{ item.num }}</span>
                <el-button type="text" @click="$emit('deleteCart')">删除</el-button>
            </li>
            <li>
                <span>￥{{ item.bl_price }}</span>
            </li>
            <li>
                <el-input-number v-model="item.num" :step="1" size="mini" @change="cartNumChange"></el-input-number>
            </li>
            <li>
                <strong class="subtotal ns-text-color">￥{{ calculationOfPrice }}</strong>
            </li>
            <li>
                <el-button type="text" @click="$emit('deleteCart')">删除</el-button>
            </li>
        </ul>
    </div>
</template>
<script>
    import CartItemMixin from "@/views/goods/cart/common/cart-item-mixin"

    export default {
        name: "default-cart-item",
        mixins: [CartItemMixin],
        computed: {
            calculationOfPrice() {
                let subtotal = Number(this.item.bl_price * this.item.num).toFixed(2)
                // 先判断是否超出限购数量 goods_limit
                this.item.subtotal = subtotal
                return subtotal

            }
        }
    }
</script>

<style lang="scss" scoped>
    @import '../common/list.scss';

    .bundling-header {
        display: flex;
        background-color: #fff;
        align-items: center;
        p {
            padding: 10px;
            flex: 1;
            background-color: #f1f4f6;
            line-height: 0;
        }
    }
</style>
